import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "../../views/shared/layout/layout";
import { SEO } from '../../components/SEO/SEO';
import Hero from "../../views/shared/hero/Hero";
import img from "../../assets/images/hero-home.png";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <Layout>
        <SEO title="Política de privacidad | Obelion" description="Política de privacidad de Obelion P&amp;W S.L." robots="noindex nofollow" />
        <Hero title={"Política de privacidad"} photo={img} />
        <div className="docs">
            {children}
        </div>
    </Layout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`¿CON QUÉ FINALIDAD TRATAMOS SUS DATOS PERSONALES?`}</h2>
    <p>{`Obelion realiza un tratamiento de los datos personales proporcionados con la finalidad de prestar los servicios solicitados mediante formularios o a través de correos electrónicos directos. Estos servicios incluyen a su vez la atención de consultas o comentarios, la recepción de documentos curriculares y la gestión de las suscripciones a la newsletter.`}</p>
    <p>{`Obelion utilizará los datos personales únicamente para proveer los servicios solicitados por el usuario en cada caso y, en el caso de que así se indique mediante la marcación de la correspondiente casilla en los formularios sobre servicios propios relacionados con la solicitud del usuario.`}</p>
    <p>{`Obelion no cederá los datos personales de los usuarios a terceros sin solicitarles su consentimiento previamente e informarle de la finalidad de dicha cesión, salvo que tal comunicación de datos resultase necesaria para la prestación del servicio solicitado o así lo estableciera la normativa vigente.`}</p>
    <p>{`Recopilación de datos para Opiniones y Comentarios
Recopilamos información sobre usted durante el proceso de dejar un comentario en nuestro sitio. Esta información puede incluir, entre otros, su nombre, dirección de correo electrónico, dirección IP, URL del sitio web, imagen(es), calificaciones y textos.`}</p>
    <p>{`Manejar esta información también nos permite:`}</p>
    <ul>
      <li parentName="ul">{`Mostrar esta información de manera pública (como una página web o solicitud API).`}</li>
      <li parentName="ul">{`Notificarle sobre interacciones tales como aprobación o rechazo de su revisión.`}</li>
      <li parentName="ul">{`Notificarle sobre las interacciones del usuario, como las notificaciones de respuesta.`}</li>
    </ul>
    <p>{`Recopilación de datos para crear Listados a través de formularios de contacto
Podemos recopilar información sobre usted cuando envíe un formulario de contacto. Esta información puede incluir, entre otros, su nombre, dirección de correo electrónico, dirección IP y textos de contacto.`}</p>
    <p>{`Manejar esta información también nos permite:`}</p>
    <ul>
      <li parentName="ul">{`Enviar su mensaje de contacto y detalles al correo electrónico de contacto del listado.`}</li>
      <li parentName="ul">{`Controlar el sistema de contactos para detectar spam y abusos indebidos.`}</li>
    </ul>
    <h2>{`¿POR CUÁNTO TIEMPO CONSERVAREMOS SUS DATOS?`}</h2>
    <p>{`La información relativa al interesado será conservada durante el periodo de vigencia de la relación comercial, mientras no se solicite la supresión de los mismos; así como durante el plazo de prescripción de las acciones que pudieran surgir en relación con la presente relación. A lo largo de ese periodo los datos se podrán poner a disposición de la Administración Pública con competencia en la materia, previa solicitud de los mismos por causa justificada.`}</p>
    <p>{`Por otro lado, los datos personales proporcionados para fines promocionales se seguirán conservando una vez finalizada la relación comercial hasta que el interesado no revoque el consentimiento, todo ello sin perjuicio de su derecho de supresión u oposición.`}</p>
    <h2>{`¿CUÁL ES LA LEGITIMACIÓN PARA EL TRATAMIENTO DE SUS DATOS?`}</h2>
    <p>{`La base legal para el tratamiento de sus datos es la ejecución de la relación comercial en la que Ud. es parte, de manera que podamos gestionar, desarrollar y controlar la correspondiente relación. Todos los datos que le solicitamos tienen el carácter de obligatorio y necesario para la prestación de nuestros servicios, por lo que la inobservancia de algunos puede imposibilitar la prestación de los mismos. Con la misma finalidad, no le solicitaremos, y Ud. no está obligado a aportar, datos que no guarden relación con la finalidad pretendida, ni aquellos otros que sean excesivos, improcedentes o inadecuados con arreglo a dicha finalidad.`}</p>
    <p>{`En relación con el envío de comunicaciones comerciales la base legal es el consentimiento que se le solicita y Usted otorga libremente.`}</p>
    <h2>{`¿A QUÉ DESTINATARIOS SE COMUNICARÁN SUS DATOS?`}</h2>
    <p>{`Obelion contrata su infraestructura virtual según el modelo de computación en la nube a través de diversos proveedores de hosting y de otros servicios externos, como son Godaddy, Google y Treebole Clínicas (entre otros). Dichos proveedores cuentan con sus servidores en Europa o, en su defecto, se encuentran adheridos al EU-US Privacy Shield.`}</p>
    <h2>{`¿CUÁLES SON SUS DERECHOS CUANDO NOS FACILITA SUS DATOS?`}</h2>
    <ul>
      <li parentName="ul">{`Derecho de acceso: el Usuario tiene derecho a obtener confirmación sobre si Obelion trata datos personales que le conciernen o no, así como a acceder a los mismos.`}</li>
      <li parentName="ul">{`Derecho de rectificación: el Usuario tiene derecho a solicitar la rectificación de aquellos datos que resulten inexactos.`}</li>
      <li parentName="ul">{`Derecho de supresión: el Usuario tiene derecho a solicitar la supresión de sus datos, entre otros motivos, cuando los datos ya no sean necesarios en relación con los fines para los que fueron recogidos.`}</li>
      <li parentName="ul">{`Derecho a la limitación del tratamiento: el Usuario, bajo la premisa de que concurran determinadas circunstancias, podrá solicitar la limitación del tratamiento de sus datos, en cuyo caso Obelion podrá utilizarlos para el ejercicio o defensa de reclamaciones, para la protección de derechos o por razones de interés público.`}</li>
      <li parentName="ul">{`Derecho a la portabilidad de los datos: el Usuario tiene derecho a que sus datos, cuando así lo solicite, sean enviados a otra empresa.`}</li>
      <li parentName="ul">{`Derecho de oposición: el Usuario tiene derecho, por motivos relacionados con su situación particular, a oponerse al tratamiento de sus datos personales. Obelion dejará de tratar dichos datos, salvo que existan motivos legítimos imperiosos, o para el ejercicio o la defensa de reclamaciones.`}</li>
    </ul>
    <p>{`El Usuario podrá ejercer sus derechos en los términos que legalmente se establecen dirigiéndose a Obelion a través de la siguiente dirección de correo electrónico: `}<a parentName="p" {...{
        "href": "mailto:info@obelion.org"
      }}>{`info@obelion.org`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      